export const useDataJd = () => {
  const { localeProperties, locales } = useI18n();

  const route = useRoute();
  const currentHost = () => {
    return "https://" + localeProperties.value.domain;
  };

  const currentURL = (slashfinal: boolean = false) => {
    var url = currentHost() + route.path;
    if (slashfinal) {
      
    }
    return url
  };
  const assets = (path: string) => {
    return currentHost() + path;
  };
  const inLanguages = (current: boolean = false) => {
    if (current) {
      return localeProperties.value.iso;
    } else {
      var ars: string[] = [];
      (locales.value as Array<{ iso: string }>).forEach((locale) => {
        ars.push(locale.iso);
      });
      return ars;
    }
  };
  return {
    currentHost,
    assets,
    currentURL,
    inLanguages,
  };
};
